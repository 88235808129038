import React, { useState } from "react";
import { Link } from "gatsby";
import { RouteComponentProps } from "@reach/router";
import { connector, ContainerProps } from "./containers/Signup.container";
import * as Buttons from "../components/Buttons";
import { ArrowLeft } from "../components/Icons/ArrowLeft";

type SignupProps = {
  isFetching: boolean;
  fromAction: "like" | "publish" | null;
  from?: string;
  onSubmit: (params: {
    email: string;
    password: string;
    passwordValidation: string;
    firstName: string;
    lastName: string;
    username: string;
  }) => any;
  onClickGoogle: Function;
};

const SignupWrapper: React.FC<SignupProps> = ({
  onSubmit,
  onClickGoogle,
  from,
  isFetching,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit({
      password,
      passwordValidation,
      email,
      username,
      firstName,
      lastName,
    });
  };

  return (
    <div className="flex items-center justify-center min-h-screen px-4 py-12 bg-gray-100 sm:px-6 lg:px-8">
      <div className="fixed top-0 w-full">
        <div className="container">
          <Link to={from || '/'}>
            <button className="px-4 py-4">
              <ArrowLeft className="text-gray-600" />
            </button>
          </Link>
        </div>
      </div>

      <div className="w-full max-w-md">
        <div>
          <h1 className="mt-6 text-3xl font-extrabold leading-9 text-center text-gray-900">
            Créer un compte
          </h1>
          <p className="mt-2 text-sm leading-5 text-center text-gray-600">
            Ou {""}
            <Link
              to="/se-connecter/"
              className="font-bold text-gray-600 underline transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:underline"
            >
              se connecter avec son compte
            </Link>
          </p>
        </div>

        <Buttons.Google
          label="Créer un compte avec Google"
          onClick={() => onClickGoogle()}
        />

        <div className="flex items-center justify-between mt-4">
          <span className="w-1/5 border-b lg:w-1/4"></span>

          <span className="text-xs text-center text-gray-500 uppercase">
            ou remplir directement
          </span>

          <span className="w-1/5 border-b lg:w-1/4"></span>
        </div>

        <form className="mt-4" onSubmit={submit}>
          <div className="rounded-md shadow-sm">
            <div>
              <input
                aria-label="Nom"
                name="last_name"
                type="text"
                required
                className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Nom"
                value={lastName}
                onChange={(evt) => setLastName(evt.target.value)}
              />
            </div>
            <div className="-mt-px">
              <input
                aria-label="Prénom"
                name="first_name"
                type="text"
                required
                className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Prénom"
                value={firstName}
                onChange={(evt) => setFirstName(evt.target.value)}
              />
            </div>
            <div className="-mt-px">
              <input
                aria-label="Pseudo"
                name="pseudo"
                type="text"
                className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Pseudo (non obligatoire)"
                value={username}
                onChange={(evt) => setUsername(evt.target.value)}
              />
            </div>
            <div className="-mt-px">
              <input
                aria-label="Adresse email"
                name="email"
                type="email"
                required
                className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Adresse email"
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
              />
            </div>
            <div className="-mt-px">
              <input
                aria-label="Mot de passe"
                name="password"
                type="password"
                required
                className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Mot de passe"
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
              />
            </div>
            <div className="-mt-px">
              <input
                aria-label="Valider le mot de passe"
                name="password-validation"
                type="password"
                required
                className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
                placeholder="Valider le mot de passe"
                value={passwordValidation}
                onChange={(evt) => setPasswordValidation(evt.target.value)}
              />
            </div>
          </div>

          <div className="mt-6">
            <Buttons.Signin label={"Créer un compte"} isFetching={isFetching} />
          </div>
        </form>
      </div>
    </div>
  );
};

export const SignupContainer: React.FC<ContainerProps & RouteComponentProps> = (
  props
) => <SignupWrapper {...props} />;

export const Signup = connector(SignupContainer);
