import React from "react"
import { PageProps } from "gatsby"

import { Signup } from "../routes/Signup"
import { Seo } from "../components/Seo/Seo"

export default ({ location }: PageProps) => (
  <>
    <Seo
      title="Créer un compte | Temple du Haïku"
      description="Pour écrire et aimer des haïkus, il faut se créer un compte gratuit."
    />
    <Signup location={location} />
  </>
)
